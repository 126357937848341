module.exports = [{
      plugin: require('../plugins/gatsby-source-birdeye-locations/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-69069630-1"],"gtagConfig":{},"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#009ade","theme_color":"#009ade","display":"minimal-ui","icon":"src/images/leap-favicon-transbg-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"970293ae99586c4f120e4e452e429cb8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wpg.leapkidsdental.com/graphql","schema":{"perPage":10,"requestConcurrency":2,"previewRequestConcurrency":2,"timeout":1000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"custom":[{"name":["Lufga"],"file":"/fonts/Lufga.css"},{"name":["VAGRounded"],"file":"/fonts/vag-rounded.css"},{"name":["Bangel-BlackRounded"],"file":"/fonts/bangel-black-rounded.css"}],"web":[{"name":"Roboto Condensed","file":"https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
